module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Sarasana","short_name":"Link free","start_url":"/","background_color":"#262626","theme_color":"#d3c8c2","display":"fullscreen","icon":"content/favicon.png","icon_options":{"purpose":"maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6aa3264bca15d8c89c15272a9f19d530"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1540,"linkImagesToOriginal":false,"showCaptions":true,"quality":80,"withWebp":true,"tracedSVG":true,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Sarasana: CMS","htmlFavicon":"content/favicon.png","modulePath":"/opt/build/repo/node_modules/@marscollective/gatsby-theme-link-free/src/cms","manualInit":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-plausible/gatsby-browser.js'),
      options: {"plugins":[],"domain":"links.sarasana.studio"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
